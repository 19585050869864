import { Modal, Container, Row, Col, Button } from "react-bootstrap";
import { ReactComponent as IconFechar } from "../assets/icons/icon-close.svg";
import { dateComparePeriod } from "../utils/utils";

export const ModalRefiliacaoComponent = (props: any) => {
  return (
    <Modal
      {...props}
      size="xl"
      className="modal-refiliacao"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header className="justify-content-end">
        <div
          className="d-flex align-items-center pointer"
          onClick={props.onHide}
        >
          <strong className="text-white me-2">Fechar</strong>
          <IconFechar stroke="white" />
        </div>
      </Modal.Header>

      {dateComparePeriod(`${process.env.REACT_APP_ENVIRONMENT === "dev" ? '08/20/2024' : '08/28/2024'}`, "08/31/2024").insidePeriod ?
        <Modal.Body className="refiliacao-campaign">
          <Row className="align-items-center">
            <Col className="col-auto d-xl-none pt-0 pt-md-4 px-1 px-md-0">
              <img className="img-fluid w-100" src="/todos/2024/refiliacao/voltapracasa/banner.png" alt="Refiliação" />
            </Col>
            <Col className="mx-2 px-md-0 mt-md-4">
              <h1 className="text-text-link">
              Quem voltar só tem a
                {' '}
                <br className="d-xl-block" />
                ganhar!
              </h1>
              <p> Dívida zerada + R$29,70 de cashback +
                <br className="d-xl-block" />
                2ª mensalidade gratuita.
                <br className="d-none d-xl-block" />
              </p>

              <Col className="col-auto pt-4">
                <Button
                  className="button-size"
                  onClick={props.onHide}
                  variant="secondary"
                  size="lg">
                  Quero aproveitar!
                </Button>

                <div className="text-center text-md-start">
                <h6>
                  Pague R$59,40 referente a taxa de adesão e 1ª mensalidade. Cashback de R$29,70
                  <br className="d-xl-block" />
                  referente ao valor da adesão
                </h6>
              </div>
              </Col>
              

            </Col>
            <Col className="col-auto  d-none d-xl-block">
              <img src="/todos/2024/refiliacao/voltapracasa/banner.png" alt="Refiliação" />
            </Col>
          </Row>
        </Modal.Body>
        :
        <Modal.Body className="refiliacao">
          <Row className="align-items-center">
            <Col className="col-auto d-xl-none pt-4">
              <img src="/todos/ilustracao-refiliacao-mobile.png" alt="Refiliação" />
            </Col>
            <Col>
              <h1 className="text-text-link">
                Que bom ter você de
                {' '}
                <br className="d-xl-block" />
                volta conosco!
              </h1>
              <p> O {" "}
                <strong>Cartão de TODOS </strong>preparou um {" "}
                <br className="d-xl-block" />
                montão de   descontos, facilidade <br className="d-none d-xl-block" /> em <br className="d-xl-none" /> agendar consultas e dinheiro de volta! {" "}
              </p>

              <Col className="col-auto d-xl-pt-3">
                <Button
                  onClick={props.onHide}
                  variant="secondary" size="lg">
                  Atualizar meu cadastro
                </Button>
              </Col>

            </Col>
            <Col className="col-auto  d-none d-xl-block">
              <img src="/todos/ilustracao-refilacao.svg" alt="Refiliação" />
            </Col>
          </Row>
        </Modal.Body>
      }
    </Modal>
  );
};
