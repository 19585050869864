import { useEffect, useState, useContext } from "react";
import { VoucherService } from "../../servicos";
import { CheckoutContext } from "../../context/checkout.provider";
import { dateComparePeriod } from "../../utils/utils";

export const Logic = (props: { form: any, alterarDescontoMensalidade: any, configuration: any, alterarVoucherInvalido: any, alterarDescontoAdesao: any, voucher: any, valorComDescontoMensalidade: any, valorComDescontoAdesao: any }) => {
    const {
      adesao,
      mensalidade,
      setAdesaoVoucher,
      setMensalidadeVoucher,
      setTotalVoucher,
      setVoucher,
      isRefiliacao
    } = useContext(CheckoutContext);

    const [voucherValido, alterarVoucherValido] = useState(false);
    const [voucherNaoEncontrado, alterarVoucherNaoEncontrado] = useState(false);
    const [loadingVoucher, setLoadingVoucher] = useState(false);

    const [voucherLocalValidacao, alterarVoucherLocalValidacao] = useState("");
    const [temCashbackMensalidade, alteraTtemCashbackMensalidade] = useState(false);
    const [temCashbackAdesao, alterarTemCashbackAdesao] = useState(false);

    const [valorMensalidade, alterarValorMensalidade] = useState(0);
    const [valorAdesao, alterarValorAdesao] = useState(0);

    const [valorDescontoMensalidade, alterarValorDescontoMensalidade] = useState(0);
    const [valorDescontoAdesao, alterarValorDescontoAdesao] = useState(0);

    const [valorComDescontoMensalidade, alterarValorComDescontoMensalidade] = useState(0);
    const [valorComDescontoAdesao, alterarValorComDescontoAdesao] = useState(0);

    const [lockField, alterarlockField] = useState(false);

    useEffect(() => {
        if (adesao !== 0 && mensalidade !== 0) {
            alterarValorMensalidade(adesao);
            alterarValorAdesao(mensalidade);
        }
    }, [adesao, mensalidade])

    const buscarVoucher = async (voucherTratado: string) => {
        if (voucherTratado.length === 0) {
            alterarVoucherNaoEncontrado(false);
            alterarVoucherValido(false);
            setVoucher('');
            alterarVoucherLocalValidacao('');
            return;
        }
        
        if (voucherLocalValidacao !== voucherTratado) {
            setLoadingVoucher(true);
            alterarValorDescontoMensalidade(0);
            alterarValorDescontoAdesao(0);
            alterarVoucherLocalValidacao(voucherTratado);
            alteraTtemCashbackMensalidade(false);
            alterarTemCashbackAdesao(false);
            alterarVoucherValido(false);
            var ativarToken = false;

            if (voucherTratado.toUpperCase() === 'TODOS7K') {
                if(props.configuration.linkAfiliado || isRefiliacao) {
                    props.alterarVoucherInvalido(true);
                    alterarVoucherNaoEncontrado(true);
                    setLoadingVoucher(false);    
                    return;
                }
            }

            
            /*
             * Work-arround feito para resolver o problema do voucher do Dia das Mães para cashback na primeira mensalidade
             * Motivo: A API de busca do voucher não possui essa informação vinculada a um ID de serviço usado pelo Checkout (1, 4, 18 e 19)
             * (voucher pag.logic-68 + inicio pag.logic-113)
            */ 
            // if(dateComparePeriod(`${process.env.REACT_APP_ENVIRONMENT === "dev" ? '06/18/2024' : '06/24/2024'}`, "06/30/2024").insidePeriod && voucherTratado === 'GOLACOZICO') {
            //     alteraTtemCashbackMensalidade(true);
            //     setVoucher(voucherTratado);
            //     ativarToken = true;
            //     alterarVoucherValido(true);
            //     props.alterarVoucherInvalido(false);
            //     alterarVoucherNaoEncontrado(false);
            // }

            // else {

                var resp = await VoucherService().buscarVoucher(voucherTratado, props.form);
    
                if (resp != null && resp.valido && resp.servicos != null) {
                    var respMensalidade = resp.servicos.find(x => x.id === (props.configuration.linkAfiliado ? 1 : 18));
                    var respAdesao = resp.servicos.find(x => x.id === (props.configuration.linkAfiliado ? 4 : 19));
                    
                    if (respMensalidade !== null && respMensalidade !== undefined) {
                        if (respMensalidade.possuiDesconto) {
                            props.alterarDescontoMensalidade(respMensalidade!.valorComDesconto!);
    
                            alterarValorDescontoMensalidade(respMensalidade.desconto!);
                            alterarValorComDescontoMensalidade(respMensalidade.valorComDesconto!);
                            alterarValorMensalidade(respMensalidade.valorMensal!);
                        }
                        alteraTtemCashbackMensalidade(!respMensalidade.possuiDesconto!);
                        ativarToken = true;
                        setVoucher(voucherTratado);
                    }
    
                    if (respAdesao !== null && respAdesao !== undefined) {
                        if (respAdesao.possuiDesconto) {
                            props.alterarDescontoAdesao(respAdesao.valorComDesconto!);
    
                            alterarValorDescontoAdesao(respAdesao.desconto!);
                            alterarValorComDescontoAdesao(respAdesao.valorComDesconto!);
                            alterarValorAdesao(respAdesao.valorMensal!);
                        }
                        alterarTemCashbackAdesao(!respAdesao.possuiDesconto!);
                        ativarToken = true;
                    }
    
                    alterarVoucherValido(ativarToken);
    
                    props.alterarVoucherInvalido(!ativarToken);
                    alterarVoucherNaoEncontrado(!ativarToken);
    
                    var indexvoucher = window.dataLayer?.findIndex((x: { customEvent: string; }) => x.customEvent === "voucher");
                    if (indexvoucher < 0) {
                        var dataLayer = window.dataLayer || []                    
                        dataLayer.push({
                            'event': 'checkout',
                            'customEvent': 'voucher',
                            'ecommerce': {
                                'checkout': {
                                    'actionField': { 'step': 3 },
                                    'voucher': [{
                                        'voucher': voucherTratado,
                                        'brand': 'CDT Checkout',
                                    }]
                                }
                            }
                        });
                    } else {
                        try {
                            window.dataLayer[indexvoucher].ecommerce.checkout.voucher[0].voucher = voucherTratado;
                        } catch (error) {
                            return;
                        }
                    }
                } else {
                    ativarToken = false;
                    props.alterarVoucherInvalido(true);
                    alterarVoucherNaoEncontrado(true);
                }
        }
        setLoadingVoucher(false);
    }

    function getTotal() {
        var valor1 = valorDescontoMensalidade > 0 ? valorComDescontoMensalidade : valorMensalidade;
        var valor2 = valorDescontoAdesao > 0 ? valorComDescontoAdesao : valorAdesao;
        setMensalidadeVoucher(valor1 !== undefined ? valor1 : 0);
        setAdesaoVoucher(valor2 !== undefined ? valor2 : 0);
        setTotalVoucher((valor1 !== undefined ? valor1 : 0) + (valor2 !== undefined ? valor2 : 0));
        return (valor1 !== undefined ? valor1 : 0) + (valor2 !== undefined ? valor2 : 0);
    }

    async function verificarVoucher(value: any = null) {
        var idpv = (getIdpv().has || getCookie('_idpv')) && !getParamsCampanhaPublicitaria() ? true : false;
        props.alterarDescontoMensalidade(0);
        props.alterarDescontoAdesao(0);

        
        /*
         * Funcionalidade Voucher com preenchimento automático
         * - Regra para uso do voucher da campanha junto com IDPV (link do vendedor)
         * 
        */
             // aplicação do voucher 
        if (dateComparePeriod(`${process.env.REACT_APP_ENVIRONMENT === "dev" ? '10/18/2024' : '10/28/2024'}`, "10/31/2024").insidePeriod ) {
            alterarVoucherNaoEncontrado(false);
            alterarlockField(value === 'GREENFRIDAY2024' ? true : false);
        }
        await buscarVoucher(value ? value : props.voucher);


        /*
         * Funcionalidade Voucher com preenchimento automático
         * - Regra para bloquear o uso do voucher da campanha junto com IDPV (link do vendedor)
         * 
        */
        // if (dateComparePeriod(`${process.env.REACT_APP_ENVIRONMENT === "dev" ? '11/01/2023' : '11/24/2023'}`, "11/27/2023").insidePeriod && idpv && value === 'OFERTACO') {
        //     alterarVoucherNaoEncontrado(true);
        //     alterarVoucherValido(false);
        // }
        // else {
        //     alterarVoucherNaoEncontrado(false);
        //     await buscarVoucher(value ? value : props.voucher);
        //     alterarlockField(value === 'OFERTACO' ? true : false);
        // }
    }

    return {
        voucherValido,
        verificarVoucher,
        valorMensalidade,
        valorAdesao,
        valorDescontoMensalidade,
        valorDescontoAdesao,
        valorComDescontoMensalidade,
        valorComDescontoAdesao,
        getTotal,
        voucherNaoEncontrado,
        loadingVoucher,
        setLoadingVoucher,
        temCashbackMensalidade,
        temCashbackAdesao,
        lockField,
    };
}