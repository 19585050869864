import { ServicoService } from "../../servicos";
import { ActionService } from "../../servicos/action.service";
import { doubleToString, getCookie, getIdpv, getParamsCampanhaPublicitaria, setEventPageView, getCampanhaPublicitaria, redirectIDPV, acceptIDPVsCampanha } from "../../utils/utils";
import Carousel from 'react-bootstrap/Carousel'
import { useNavigate } from "react-router-dom";
import { Configuration, ServiceValuesModel } from "../../modelos"
import { AccessDTO } from "../../dto";
import { useEffect, useLayoutEffect, useState } from "react";
import axios from "axios";
import { dateComparePeriod } from "../../utils/utils";

export const Logic = () => {
    const [configuration, alterarConfiguration] = useState(require("./configuration.json") as Configuration);
    const [iniciou, alterarIniciou] = useState(false);

    const navigate = useNavigate();

    const titleCarousel = document.getElementById('title-carousel');

    const [carrossel, alterarCarrossel] = useState(configuration.home?.carousel !== undefined ? configuration.home?.carousel[0] : undefined);
    
    const [valor, alterarValor] = useState<ServiceValuesModel>({});

    const [mensalidade, alterarMensalidade] = useState(configuration.prices!.mensalidade!);
    const [adesao, alterarAdesao] = useState(configuration.prices!.adesao!);
    const [clinicoGeral, alterarClinicoGeral] = useState(configuration.prices!.clinico_geral!);
    const [demais, alterarDemais] = useState(configuration.prices!.demais!);

    useEffect(() => {
        /**
         * Regras de Checkout multi url
         * Redireciona para o site idpv.cartaodetodos.com.br
         */
        if ( window.location.host.search('assine.') >= 0 || window.location.host.search('solicite.') >= 0 )
            redirectIDPV();


        (document.getElementById("favicon") as any).href = configuration.favicon;
        setEventPageView(window.location.pathname);

        let idpv = getIdpv();

        configuration.isTestAB = false;
        configuration.id_promotor_lead = getCookie('_idpv');
        configuration.campanha_publicitaria = getCampanhaPublicitaria().has;
        configuration.linkAfiliado = (idpv.has || getCookie('_idpv')) && !configuration.campanha_publicitaria ? true : false;

        if (Carousel.defaultProps?.slide) {
            if (titleCarousel?.classList.contains('active')) {
                titleCarousel?.classList.remove('active');
                setTimeout(() => {
                    titleCarousel?.classList.add('active');
                }, 1);
            } else {
                titleCarousel?.classList.add('active');
            }
        }

        if (valor.valor === undefined) {
            

            if (configuration.campanha_publicitaria) {
                document.cookie = '_idpv=; Max-Age=0';
                if (configuration.id_promotor_lead) {
                    configuration.id_promotor_lead = '-';
                }
            }

            alterarConfiguration(configuration);
        }

        var count = window.localStorage.getItem("count");
        var localCount = 1;
        if (count) {
            localCount = Number.parseInt(count);
        } else {
            window.localStorage.setItem("count", localCount.toString());
        }

        configuration.count = localCount;
        
        var _uuid = getCookie('_uuid');
        configuration.ga_codigo = _uuid

        if (configuration.campanha_publicitaria) {
            document.cookie = '_idpv=; Max-Age=0';
            if (configuration.id_promotor_lead) {
                configuration.id_promotor_lead = '-';
            }
        }

        alterarConfiguration(configuration);

        /*
         * Funcionalidade Voucher com preenchimento automático
         * - Regra para uso do voucher da campanha junto com IDPV (link do vendedor)
         * 
        */

        // aplicação do voucher 
        if (dateComparePeriod(`${process.env.REACT_APP_ENVIRONMENT === "dev" ? '10/18/2024' : '10/28/2024'}`, "10/31/2024").insidePeriod 
        // && !acceptIDPVsCampanha(getIdpv().value)
    ) {
            var parameters = window.location.search;
            var voucher = getVoucher().has;

            // Regra para uso do voucher da campanha junto com IDPV (link do vendedor)
            if (parameters.length > 0 && !voucher) {
                parameters += "&voucher=GREENFRIDAY2024";
            }
            else if (parameters.length < 1) {
                parameters = "?voucher=GREENFRIDAY2024";
            }


            return navigate("/pagamento" + parameters, { state: { configuration } });


            // // Regra para bloquear o uso do voucher da campanha junto com IDPV (link do vendedor)
            // if (!idpv) {
            //     if (parameters.length > 0 && !voucher) {
            //         parameters += "&voucher=DIAMAES";
            //     }
            //     else if (parameters.length < 1) {
            //         parameters = "?voucher=DIAMAES";
            //     }
            //     return navigate("/pagamento" + parameters, { state: { configuration } });
            // }

        }

        return navigate("/pagamento" + window.location.search, { state: { configuration } });

        /**
         * INFO:
         * Código comentado devido a nova regra temporária de enviar o acesso diretamente para a página /pagamento
         */
        // const newConfiguration = configuration;
        // if (typeof newConfiguration.home?.carousel === 'object') {
        //     for (let index = 0; index < newConfiguration?.home?.carousel?.length; index++) {
        //         const element = newConfiguration?.home?.carousel[index];
        //         if (element.campaignStartDate && !dateComparePeriod(`${element.campaignStartDate}`, `${element.campaignEndDate}`).insidePeriod) {
        //             newConfiguration.home.carousel.splice(index, 1);
        //         }
        //     }
        //     alterarConfiguration(newConfiguration);
        // }

        // ServicoService().buscarValorServico(configuration.linkAfiliado && !configuration.campanha_publicitaria ? 1 : 18).then((valorNormalMensalidade: any) => {
        //     ServicoService().buscarValorServico(configuration.linkAfiliado && !configuration.campanha_publicitaria ? 4 : 19).then((valorNormalAdesao: any) => {
        //         alterarValor(valorNormalMensalidade);
        //         alterarMensalidade(valorNormalMensalidade.valor);
        //         alterarAdesao(valorNormalAdesao.valor);
        //         alterarClinicoGeral(valorNormalMensalidade.clinico_geral);
        //         alterarDemais(valorNormalMensalidade.demais);
        //         var price = valorNormalMensalidade.valor + valorNormalAdesao.valor;

        //         configuration.prices!.mensalidade = valorNormalMensalidade.valor;
        //         configuration.prices!.adesao = valorNormalAdesao.valor;
        //         configuration.prices!.clinico_geral = valorNormalMensalidade.clinico_geral;
        //         configuration.prices!.demais = valorNormalMensalidade.demais;

        //         if (typeof carrossel === 'object') {
        //             var valorString = doubleToString(valorNormalMensalidade.valor).split(",");
        //             var newTitle = carrossel?.title?.replaceAll("<div class='d-none'>{{valor}}</div>", `<span class="currency fw-normal">R$</span><span class="value">${valorString[0]}</span><span class="cents">,${valorString[1]}</span>`);
        //             carrossel.title = newTitle;
        //             alterarCarrossel(JSON.parse(JSON.stringify(carrossel)));
        //         }

        //         var productView = window.dataLayer?.findIndex((x: { event: string; }) => x.event === "productView");
        //         if (productView < 0) {
        //             dataLayer.push({
        //                 event: 'productView',
        //                 ecommerce: {
        //                     detail: {
        //                         actionField: { list: 'Home Checkout' },
        //                         products: [
        //                             {
        //                                 name: 'Filiação Cartão de Todos',
        //                                 id: 1,
        //                                 price: price,
        //                                 brand: 'CDT Site',
        //                             }
        //                         ],
        //                         items: [
        //                             {
        //                                 item_name: 'Filiação Cartão de Todos',
        //                                 item_id: 1,
        //                                 price: price,
        //                                 item_brand: 'CDT Site',
        //                             }
        //                         ]
        //                     },
        //                 },
        //             });
        //         }
        //     });
        // });

    }, []);

    function changeCarouselTitle(index) {

        if (typeof carrossel === 'object' && typeof configuration.home?.carousel === 'object') {
            if (configuration.home?.carousel[index].title!.indexOf("<div class='d-none'>{{valor}}</div>") > -1) {
                var valorString = doubleToString(mensalidade).split(",");
                var newTitle = configuration.home?.carousel[index]?.title?.replaceAll("<div class='d-none'>{{valor}}</div>", `<span class="currency fw-normal">R$</span><span class="value">${valorString[0]}</span><span class="cents">,${valorString[1]}</span>`);
                carrossel.title = newTitle;
                return alterarCarrossel(JSON.parse(JSON.stringify(carrossel)));
            }
            else {
                return alterarCarrossel(JSON.parse(JSON.stringify(configuration.home?.carousel[index])));
            }
        }
    };

    const toPagamento = async () => valor.valor !== undefined ? navigate("/pagamento" + window.location.search, { state: { configuration } }) : null;

    return {
        configuration, carrossel, alterarCarrossel, changeCarouselTitle, toPagamento, valor, mensalidade, adesao, clinicoGeral, demais
    };
}
