import { Col, Form, FormControl, InputGroup, Row, Spinner, } from "react-bootstrap";
import { dateComparePeriod, doubleToString, getVoucher } from "../../utils/utils";
import { Configuration } from "../../modelos";
import { Logic } from "./logic";
import { useContext, useLayoutEffect, useState } from "react";
import { CheckoutContext } from "../../context/checkout.provider";

export const VoucherComponente = (props:
    {
        configuration: Configuration,
        onFocus: any,
        getForm: any,
        voucher?: any,
        alterarVoucher?: any,
        descontoMensalidade?: any,
        alterarDescontoMensalidade?: any,
        descontoAdesao?: any,
        alterarDescontoAdesao?: any,
        alterarVoucherInvalido?: any,
        alterarVoucherDescription?: any
        valorComDescontoMensalidade?: any,
        valorComDescontoAdesao?: any,
    }) => {
    const componentLogic = Logic(
        {
            alterarDescontoAdesao: props.alterarDescontoAdesao,
            alterarDescontoMensalidade: props.alterarDescontoMensalidade,
            alterarVoucherInvalido: props.alterarVoucherInvalido,
            configuration: props.configuration,
            form: props.getForm(),
            voucher: props.voucher,
            valorComDescontoMensalidade: props.valorComDescontoMensalidade,
            valorComDescontoAdesao: props.valorComDescontoAdesao
        }
    );

    const { voucher: voucherTratado } = useContext(CheckoutContext);

    useLayoutEffect(() => {      
        let voucher = getVoucher();

        if ((voucher.has && props.voucher === '')) {
            props.alterarVoucher(voucher.value);
            componentLogic.verificarVoucher(voucher.value);
        }
        else if (voucherTratado && props.voucher === '') {
            props.alterarVoucher(voucherTratado);
            componentLogic.verificarVoucher(voucherTratado);
        }
    }, [props, componentLogic, voucherTratado]);

    return (
        <>
            <fieldset className="voucher w-100 my-3 position-relative">
                <legend>Você possui um voucher? Digite ele aqui</legend>
                <Form.Group controlId="voucher" className="voucher-border">
                    <InputGroup className="mb-3 mt-4">
                        <InputGroup.Text id="voucher">
                            <img
                                src={props.configuration.imageVoucher}
                                alt="Imagem Voucher"
                            />
                        </InputGroup.Text>
                        <FormControl
                            className={`text-uppercase ${componentLogic.voucherNaoEncontrado && !componentLogic.voucherValido ? 'is-invalid text-danger': ''}`}
                            size="lg"
                            type="text"
                            placeholder="ex. DESCONTAO"
                            aria-describedby="voucher"
                            value={props.voucher}
                            onChange={(event) => props.alterarVoucher(event.target.value)}
                            onFocus={() => props.onFocus()}
                            onBlur={(event) => componentLogic.verificarVoucher(event.target.value)}
                            disabled={componentLogic.lockField}
                        />
                    </InputGroup>
                    {componentLogic.loadingVoucher ? <Spinner animation="border" role="status" variant="primary" className='spinner-class'>
                        <span className="visually-hidden">Loading...</span>
                    </Spinner> : null}
                </Form.Group>
                {componentLogic.voucherValido ?
                    // 4: Adesão Franquia (IDPV - Link do vendedor)
                    // 1: Mensalidade Franquia (IDPV - Link do vendedor)

                    // 19: Adesão Nacional
                    // 18: Mensalidade Nacional

                    // 4 : 19 Adesão
                    // 1 : 18 Mensalidade
                    <small className="text-center">
                        <span className="fw-bold text-primary">Cupom ativo</span> -

                        {/* Mensalidade */}
                        {componentLogic.valorDescontoMensalidade > 0 ?
                            <>
                                <span className="voucher-detail text-muted text-center"> {props.voucher === "VOLTAPRACASA" && dateComparePeriod(`${process.env.REACT_APP_ENVIRONMENT === "dev" ? '08/20/2024' : '08/28/2024'}`, "08/31/2024").insidePeriod ? "Cashback na Adesão + 2ª mensalidade grátis" : "Desconto na primeira mensalidade"} </span>
                                <br />
                            </>
                            :
                            null
                        }
                        {componentLogic.temCashbackMensalidade ?
                            <>
                                <span className="voucher-detail text-muted text-center"> {props.voucher === "VOLTAPRACASA" && dateComparePeriod(`${process.env.REACT_APP_ENVIRONMENT === "dev" ? '08/20/2024' : '08/28/2024'}`, "08/31/2024").insidePeriod ? "Cashback na Adesão + 2ª mensalidade grátis" : "Cashback na primeira mensalidade"} </span>
                                <br />
                            </>
                            :
                            null
                        }

                        {/* Adesão */}
                        {componentLogic.valorDescontoAdesao > 0 ?
                            <span className="voucher-detail text-muted text-center"> Desconto na adesão </span>
                            :
                            null
                        }
                        {componentLogic.temCashbackAdesao ?
                            <span className="voucher-detail text-muted text-center"> Cashback na adesão </span>
                            :
                            null
                        }

                    </small>
                    : null}
                {componentLogic.voucherNaoEncontrado && !componentLogic.voucherValido ?
                    <div className="invalid-feedback d-block">Cupom inválido - não será aplicado a compra</div>
                    : null}
            </fieldset >

            {/* Resumo de descontos do voucher */}
            <hr className="m-2 mt-4 text-muted" />
            <Row className="px-1 ps-4 my-3">

                <Col xs={8} sm={8} className="ps-2"><h5 className="text-muted">1ª mensalidade</h5></Col>
                <Col xs={4} sm={4}>{componentLogic.valorDescontoMensalidade > 0 ? <del><h5 className="fw-normal text-muted">R$ {doubleToString(componentLogic.valorMensalidade)}</h5></del> : <h5 className={`fw-normal text-muted ${componentLogic.valorMensalidade! > 0 ? '' : 'shimmer'}`}>R$ {doubleToString(componentLogic.valorMensalidade)}</h5>}</Col>

                <Col sm={{ span: 4, offset: 8 }}> {componentLogic.valorDescontoMensalidade > 0 ? <h5 className="descont fw-normal">R$ {doubleToString(componentLogic.valorComDescontoMensalidade)}</h5> : null}</Col>

                <Col xs={8} sm={8} className="ps-2"><h5 className="m-0 text-muted">taxa de adesão</h5></Col>
                <Col xs={4} sm={4}>{componentLogic.valorDescontoAdesao > 0 ? <del><h5 className="m-0 fw-normal text-muted">R$ {doubleToString(componentLogic.valorAdesao)}</h5></del> : <h5 className={`m-0 fw-normal text-muted  ${componentLogic.valorAdesao! > 0 ? '' : 'shimmer'}`}>R$ {doubleToString(componentLogic.valorAdesao)}</h5>}</Col>

                <Col sm={{ span: 4, offset: 8 }}>{componentLogic.valorDescontoAdesao > 0 ? <h5 className="fw-normal">R$ {doubleToString(componentLogic.valorComDescontoAdesao)}</h5> : null}</Col>
            </Row>
            <hr className="m-2 text-muted" />
            <Row className="mb-5 px-1 ps-4">
                <Col xs={8} sm={8} className="ps-2"><h5 className="m-0 text-primary">Valor total</h5></Col>
                <Col xs={4} sm={4}><h5 className={`m-0 fw-normal text-primary ${componentLogic.getTotal()! >= 0 ? '' : 'shimmer'}`}>R$ {doubleToString(componentLogic.getTotal())}</h5></Col>
            </Row>
        </>
    );
}