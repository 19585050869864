import { ReactComponent as IconArrowDown } from '../../assets/icons/icon-arrow-down.svg';
import { ReactComponent as IconValidatedForm } from '../../assets/icons/icon-ok.svg';
import { ModalTermosComponente } from "../../componentes/modal_termos.componente";
import { Container, Row, Col, Form, Accordion, Spinner } from "react-bootstrap"
import { MetodoPagamentoComponente } from '../../componentes/metodo_pagamento';
import { FooterComponente } from "../../componentes/footer.component";
import { HeaderComponente } from "../../componentes/header.component";
import { VoucherComponente } from '../../componentes/voucher';
import { dateComparePeriod, doubleToString } from "../../utils/utils";
import InputMask from "react-input-mask";
import React, { useEffect, useState } from "react";
import { Logic } from "./logic"
import "./style.scss";
import ReactDOM from "react-dom";
import ReCAPTCHA from "react-google-recaptcha";
import CheckboxComponent from '../../componentes/checkbox.component';
import { LeadService } from '../../servicos';
import { ModalRefiliacaoComponent } from '../../componentes/modal_refiliacao_componente';
import { ModalTermosComponenteSergipe } from '../../componentes/modal_termos.componente_sergipe';
import { ModalRefiliacaoCepComponent } from '../../componentes/modal_refiliacao_cep_componente';
import { ModalRefiliacaoBlockComponent } from '../../componentes/modal_refiliacao_block_componente';


export default function Pagamento({ logic = Logic() }) {

  const [modalEvents, setModalEvents] = useState(true);

  useEffect(() => {
    const onUnload = (e) => {
      if (!logic.habilitadoPessoa && logic.termosSmsEmail && !logic.processoPagamento) {
        e.preventDefault();
        var form = logic.getForm();
        form.fechou_checkout = true;
        LeadService().sendLead(form);
        logic.sendGt7Analytics();

        return e.returnValue = '';
      }
    };

    window.addEventListener("beforeunload", onUnload);

    return () => {
      window.removeEventListener("beforeunload", onUnload);
    }
  }, [logic.habilitadoPessoa, logic.processoPagamento]);


  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = '//cdn.siteblindado.com/aw.js';
  //   document.body.appendChild(script);
  // }, []);

  //   <div id="armored_website">
  //   <param id="aw_preload" value="true" />
  //   <param id="aw_use_cdn" value="true" />
  // </div>

  const pessoa = () =>
    <>
      <Form.Group className="mb-3">
        <Form.Label>Nome completo</Form.Label>


        <Form.Control
          autoFocus={true}
          type="text"
          placeholder="ex. Maria Lucia Almeida"
          value={logic.nome}
          onChange={(event) => logic.alterarNome(event.target.value)}
          onBlur={logic.verificarNome}
          isValid={logic.nomeValido}
          isInvalid={logic.nomeInvalido}
          disabled={!logic.habilitadoPessoa}
          enterKeyHint="next"
          onKeyDown={(e) => {
            if (e.key === 'Enter')
              document.getElementById(logic.configuration.isTestAB ? "dataNasc" : "cpf")?.focus();
          }}
          required
        />
        <Form.Control.Feedback type="invalid">{logic.nomeMensagemErro}</Form.Control.Feedback>
      </Form.Group>
      {
        logic.configuration.isTestAB ?
          <>
            <Form.Group className="mb-3">
              <Form.Label>Data de Nascimento</Form.Label>
              <Form.Control
                id="dataNasc"
                as={InputMask}
                maskChar=''
                mask="99/99/9999"
                type="text"
                placeholder="01/01/2000"
                onChange={(event) => logic.alterarDataNasc(event.target.value)}
                onBlur={logic.verificarDataNasc}
                isValid={logic.dataNascValido}
                isInvalid={logic.dataNascInvalido}
                disabled={!logic.habilitadoPessoa}
                required
              />
              <Form.Control.Feedback type="invalid">{logic.dataNascMensagemErro}</Form.Control.Feedback>
            </Form.Group>
          </> : <></>
      }
      <Form.Group className="mb-3">
        <Form.Label>CPF</Form.Label>
        <Form.Control
          id="cpf"
          as={InputMask}
          maskChar=''
          mask="999.999.999-99"
          type="text"
          placeholder="000.000.000-00"
          onChange={(event) => logic.alterarCpf(event.target.value)}
          onBlur={logic.verificarCpf}
          isValid={logic.cpfValido}
          isInvalid={logic.cpfInvalido}
          disabled={!logic.habilitadoPessoa}
          enterKeyHint="next"
          onKeyDown={(e) => {
            if (e.key === 'Enter')
              document.getElementById("email")?.focus();
          }}
          required
        />

        {logic.modalRefiliacaoMessageShow &&
          <p>CPF já cadastrado, deseja reativar seu cartão? {" "}
            <button
              type="button"
              onClick={() => logic.setModalRefiliacaoShow(true)}
              className="fw-normal text-primary underline text-link">
              <strong> Sim</strong>
            </button>
          </p>
        }


        <Form.Control.Feedback type="invalid">{logic.cpfMensagemErro}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>E-mail</Form.Label>
        <Form.Control
          id="email"
          type="text"
          placeholder="ana@gmail.com"
          value={logic.email}
          onChange={(event) => logic.alterarEmail(event.target.value)}
          onBlur={logic.verificarEmail}
          isValid={logic.emailValido}
          isInvalid={logic.emailInvalido}
          disabled={!logic.habilitadoPessoa}
          enterKeyHint="next"
          onKeyDown={(e) => {
            if (e.key === 'Enter')
              document.getElementById("telefone")?.focus();
          }}
          required
        />
        <Form.Control.Feedback type="invalid">{logic.emailMensagemErro}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Telefone</Form.Label>
        <Form.Control
          id="telefone"
          type="text"
          as={InputMask}
          maskChar=''
          mask="(99) 99999-9999"
          value={logic.telefone}
          placeholder="(11) 99999-9999"
          onChange={(event) => logic.alterarTelefone(event.target.value)}
          onBlur={logic.verificarTelefone}
          isValid={logic.telefoneValido}
          isInvalid={logic.telefoneInvalido}
          disabled={!logic.habilitadoPessoa}
          enterKeyHint="next"
          onKeyDown={(e) => {
            if (e.key === 'Enter')
              document.getElementById(!logic.configuration.isTestAB ? "maiorDezo" : "termos")?.focus();
          }}
          required
        />
        <Form.Control.Feedback type="invalid">{logic.telefoneMensagemErro}</Form.Control.Feedback>
      </Form.Group>
      {
        !logic.configuration.isTestAB ?
          <CheckboxComponent
            title="Maior18"
            value={logic.maiorDezoito}
            customLabel={<>Maior de 18 anos</>}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              logic.alterarMaiorDezoito(value => value = event.target.checked);
            }}
            onBlur={logic.verificarMaiorDezoito}
            isInvalid={logic.maiorDezoitoInvalido}
            errorMessage={logic.maiorDezoitoMensagemErro}
            disabled={!logic.habilitadoPessoa}
            required
          />
          : <></>
      }
      <CheckboxComponent
        title="termos"
        value={logic.termos}
        customLabel={<>Li e aceito os <button type="button" onClick={() => logic.setModalShow(true)} className="underline text-link-terms"><strong>termos e condições</strong></button></>}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          logic.alterarTermos(value => value = event.target.checked);
        }}
        onBlur={logic.verificarTermos}
        isInvalid={logic.termosInvalido}
        errorMessage={logic.termosMensagemErro}
        disabled={!logic.habilitadoPessoa}
        required
      />
      <CheckboxComponent
        title="termosSmsEmailTel"
        value={logic.termosSmsEmail}
        customLabel={<>Eu aceito notificações e contato via <b>SMS</b>, <b>e-mail</b> ou <b>telefone</b></>}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          var value = event.target.checked;
          logic.sendOptIn(value);
          logic.verificarTermos();
          logic.alterarTermosSmsEmail(value);
        }}
        disabled={!logic.habilitadoPessoa}
      />
    </>

  const endereco = () => <>
    <div className="d-flex justify-content-end align-items-start">
      <Form.Group className="mb-4 w-100">
        <Form.Label>CEP</Form.Label>
        <Form.Control
          ref={(refCep: any) => {
            if (refCep && window.innerWidth < 1199.98 && logic.canFocusCep) {
              logic.alterarCanFocusCep(false);
              (ReactDOM.findDOMNode(refCep) as any).focus();
            }
          }}
          as={InputMask}
          maskChar=''
          mask="99999-999"
          type="text"
          placeholder="0000-000"
          value={logic.cep}
          onChange={(event) => logic.alterarCep(event.target.value)}
          onBlur={() => logic.verificarCep(true)}
          isValid={logic.cepValido}
          isInvalid={logic.cepInvalido}
          disabled={!logic.habilitadoEndereco || logic.cpfInvalido}
          enterKeyHint="next"
          onKeyDown={(e) => {
            if (e.key === 'Enter')
              document.getElementById("rua")?.focus();
          }}
          required
        />
        <Form.Control.Feedback type="invalid">{logic.cepMensagemErro}</Form.Control.Feedback>
        <Form.Text style={{ float: "right", display: "none" }}>
          Não sabe seu CEP?  <a href="https://buscacepinter.correios.com.br/app/localidade_logradouro/index.php" target="_blank" rel="noreferrer" className="underline">Clique aqui</a>
        </Form.Text>
      </Form.Group>
      {logic.loadingCep ? <Spinner animation="border" role="status" variant="primary" className='position-absolute spinner-class'>
        <span className="visually-hidden">Loading...</span>
      </Spinner> : null}
    </div>
    <Form.Group className="mb-3">
      <Form.Label>Rua</Form.Label>
      <Form.Control
        id="rua"
        type="text"
        placeholder="Novo oriente da boa ventura"
        value={logic.rua}
        onChange={(event) => logic.alterarRua(event.target.value)}
        onBlur={() => logic.verificarRua()}
        isValid={logic.ruaValido}
        isInvalid={logic.ruaInvalido}
        disabled={!logic.habilitadoEndereco || logic.cpfInvalido}
        enterKeyHint="next"
        onKeyDown={(e) => {
          if (e.key === 'Enter')
            document.getElementById("bairro")?.focus();
        }}
        required
      />
      <Form.Control.Feedback type="invalid">{logic.ruaMensagemErro}</Form.Control.Feedback>
    </Form.Group>
    <Form.Group className="mb-3">
      <Form.Label>Bairro</Form.Label>
      <Form.Control
        id="bairro"
        type="text"
        placeholder="Jardim das Oliveiras"
        value={logic.bairro}
        onChange={(event) => logic.alterarBairro(event.target.value)}
        onBlur={() => logic.verificarBairro()}
        isValid={logic.bairroValido}
        isInvalid={logic.bairroInvalido}
        disabled={!logic.habilitadoEndereco || logic.cpfInvalido}
        enterKeyHint="next"
        onKeyDown={(e) => {
          if (e.key === 'Enter')
            document.getElementById("numero")?.focus();
        }}
        required
      />
      <Form.Control.Feedback type="invalid">{logic.bairroMensagemErro}</Form.Control.Feedback>
    </Form.Group>
    {logic.bairro !== "" && logic.cidade !== "" && logic.estado !== "" ?
      <>
        <small className="fw-normal text-muted ps-4">{logic.cidade} - {logic.estado}</small>
      </>
      : <></>}
    <Row className="my-3">
      <Col>
        <Form.Group>
          <Form.Label>Número</Form.Label>
          <Form.Control
            id="numero"
            as={InputMask}
            maskChar=''
            mask="999999"
            type="text"
            placeholder="10"
            value={logic.numero}
            onChange={(event) => logic.alterarNumero(event.target.value)}
            onBlur={logic.verificarNumero}
            isValid={logic.numeroValido}
            isInvalid={logic.numeroInvalido}
            disabled={!logic.habilitadoEndereco || logic.cpfInvalido}
            enterKeyHint="next"
            onKeyDown={(e) => {
              if (e.key === 'Enter')
                document.getElementById("complemento")?.focus();
            }}
            required
          />
          <Form.Control.Feedback type="invalid">{logic.numeroMensagemErro}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Label>Complemento</Form.Label>
          <Form.Control
            id="complemento"
            type="text"
            placeholder="apt 01"
            value={logic.complemento}
            onChange={(event) => logic.alterarComplemento(event.target.value)}
            disabled={!logic.habilitadoEndereco || logic.cpfInvalido}
            enterKeyHint="next"
            onKeyDown={(e) => {
              if (e.key === 'Enter')
                document.getElementById("complemento")?.blur();
            }}
            onBlur={() => {
              if (window.innerWidth < 1199.98) {
                logic.desabilitarEndereco();
                setTimeout(() => {
                  (document.getElementById("pagamentoAccordion")?.getElementsByClassName("accordion-button")[0] as any).click();
                  logic.alterarCanFocusNumero(true);
                }, 0);
              }
            }}
          />
        </Form.Group>
      </Col>
    </Row>
    <Row>
      <Col>
        {logic.isCepSergipe &&

          <CheckboxComponent
            title="termos"
            value={logic.termos}
            customLabel={<>Li e aceito o <button type="button" onClick={() => logic.setShowModalSergipe(true)} className="underline text-link-terms"><strong>contrato de adesão Sergipe</strong></button></>}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              logic.alterarTermos(value => value = event.target.checked);
            }}
            onBlur={logic.verificarTermos}
            isInvalid={logic.termosInvalido}
            errorMessage={logic.termosMensagemErro}
            disabled={!logic.habilitadoPessoa}
            required
          />
        }
      </Col>
    </Row>
  </>;

  const pagamento = () => <>
    {logic.configuration !== undefined ?
      <>
        <VoucherComponente configuration={logic.configuration} onFocus={logic.desabilitarEndereco}
          getForm={logic.getForm} voucher={logic.voucher} alterarVoucher={logic.alterarVoucher}
          descontoMensalidade={logic.descontoMensalidade} descontoAdesao={logic.descontoAdesao}
          alterarDescontoMensalidade={logic.alterarDescontoMensalidade} alterarDescontoAdesao={logic.alterarDescontoAdesao}
          alterarVoucherInvalido={logic.alterarVoucherInvalido} alterarVoucherDescription={logic.alterarVoucherDescription}
        />

        {/* <h5 className="text-muted text-center fw-normal my-4">
          Selecione um dos nossos métodos de pagamento
        </h5> */}

        <div className={logic.cpfInvalido || logic.emailInvalido || !logic.termos ? "col disabled" : ""} style={logic.habilitadoPessoa === false ? { cursor: "not-allowed" } : { cursor: "pointer" }}>
          <MetodoPagamentoComponente
            configuration={logic.configuration}
            handleSubmit={logic.handleSubmit}
            disableFields={false}
            formularioPagamento={logic.getForm}
            desabilitarEndereco={logic.desabilitarEndereco}
            loading={logic.loading}
            alterarLoading={logic.alterarLoading}
            iniCreditoAberto={true}
            canFocusNumero={logic.canFocusNumero}
            alterarCanFocusNumero={logic.alterarCanFocusNumero}
            recaptchaRef={logic.recaptchaRef}
            isRefiliacao={logic.idFiliadoRefiliacao !== 0}
          />
        </div>
      </>
      : null
    }
  </>;

  return (
    <>
      <ReCAPTCHA
        ref={logic.recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
      />
      <main>
        <HeaderComponente configuration={logic.configuration} bgSolido={true} />
        <Container fluid="xxl" className="g-0 g-xl-2">
          <Row className="h-responsive pt-4 g-0 g-xl-2">
            <Col className="d-xl-none">
              <Accordion defaultActiveKey="0" activeKey={logic.posicaoAccordion}>
                <Accordion.Item eventKey="0" >
                  <Accordion.Header id="pessoaAccordion">Cadastro</Accordion.Header>
                  <Accordion.Body>
                    <h5 className="text-muted mb-4 fw-normal">Os seus dados serão utilizados apenas para realização de cadastro.</h5>
                    {pessoa()}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item id="endAccordion" eventKey="1" onTouchStart={() => { logic.desabilitarPessoa(); logic.verificarTermos(); !logic.configuration.isTestAB ? logic.verificarMaiorDezoito() : logic.verificarDataNasc() }} onClick={() => { logic.desabilitarPessoa(); logic.verificarTermos(); !logic.configuration.isTestAB ? logic.verificarMaiorDezoito() : logic.verificarDataNasc() }}>
                  <Accordion.Header id="enderecoAccordion">Endereço</Accordion.Header>
                  <Accordion.Body>
                    <h5 className="text-muted fw-normal mb-4">Preencha os campos abaixo com o seu endereço.</h5>
                    {endereco()}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item id="pagamentoAccordion" eventKey="2" onTouchStart={() => logic.desabilitarEndereco()} onClick={() => logic.desabilitarEndereco()}>
                  <Accordion.Header>Pagamento</Accordion.Header>
                  <Accordion.Body>
                    {pagamento()}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col className="d-none d-xl-block px-5 pb-4 pagamento-form">
              <Row className="d-flex justify-content-between">
                <Col className={logic.habilitadoPessoa === false ? "disabled" : ""} style={logic.habilitadoPessoa === false ? { cursor: "not-allowed" } : { cursor: "pointer" }}>
                  <Row>
                    <Col sm={12} className="d-flex justify-content-between">
                      <h2 className="h3">Cadastro</h2>
                      <IconArrowDown className={logic.habilitadoPessoa === false ? "d-none" : ""} />
                      <IconValidatedForm className={logic.habilitadoPessoa === false ? "validated" : "d-none"} />
                    </Col>
                    <Col sm={12}>
                      <h5 className="text-muted fw-normal py-4">Os seus dados serão utilizados apenas para realização de cadastro.</h5>
                    </Col>
                    <Col>{pessoa()}</Col>
                  </Row>
                </Col>
                <Col sm="auto" className="px-5">
                  <div className="d-flex h-100">
                    <div className="vr"></div>
                  </div>
                </Col>
                <Col className={logic.habilitadoEndereco === false || logic.cpfInvalido ? "disabled" : ""} style={logic.habilitadoPessoa === false ? { cursor: "not-allowed" } : { cursor: "pointer" }}>
                  <Row>
                    <Col sm={12} className="d-flex justify-content-between">
                      <h2 className="h3">Endereço</h2>
                      <IconArrowDown className={logic.habilitadoEndereco === false ? "d-none" : ""} />
                      <IconValidatedForm className={logic.habilitadoEndereco === false ? "validated" : "d-none"} />
                    </Col>
                    <Col sm={12}>
                      <h5 className="text-muted fw-normal py-4">Preencha os campos abaixo com o seu endereço.</h5>
                    </Col>
                    <Col>{endereco()}</Col>
                  </Row>
                </Col>
                <Col sm="auto" className="px-5">
                  <div className="d-flex h-100">
                    <div className="vr"></div>
                  </div>
                </Col>
                <Col>
                  <Row>
                    <Col sm={12} className="d-flex justify-content-between">
                      <h2 className="h3">Pagamento</h2>
                      <IconArrowDown />
                    </Col>
                    <Col>{pagamento()}</Col>
                  </Row>
                </Col>
                <Col sm={12} className="mt-5 pt-5">
                  <div className="text-center" dangerouslySetInnerHTML={{ __html: logic.configuration.helpTextOthers! }}></div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </main>

      <footer>
        <FooterComponente configuration={logic.configuration} divideTela={false} />
      </footer>

      <ModalTermosComponenteSergipe
        show={logic.showModalSergipe}
        onHide={() => logic.setShowModalSergipe(false)}
      />

      <ModalTermosComponente
        show={logic.modalShow}
        onHide={() => logic.setModalShow(false)}
      />

      <ModalRefiliacaoBlockComponent
        show={logic.modalRefiliacaoBlockShow}
        onHide={() => logic.setModalRefiliacaoBlockShow(false)}
      />

      <ModalRefiliacaoComponent
        show={logic.modalRefiliacaoShow}
        onHide={() => logic.setModalRefiliacaoShow(false)}
      />


      <ModalRefiliacaoCepComponent
        show={logic.modalRefiliacaoCepShow}
        onHide={() => logic.setModalRefiliacaoCepShow(false)}
      />

      {/* <ModalPosFiliacaoComponent
        show={true}
        onHide={() => logic.setModalRefiliacaoCepShow(false)}
      /> */}

      {/* <ModalEvents
        data={null}
        show={true}
        onHide={() => logic.setModalRefiliacaoCepShow(false)}
      /> */}


      {/* para fins de testes aqui: setModalEvents*/}
      {/* <ModalAvaliacao
        show={setModalEvents}
        onHide={() => setModalEvents(false)}
      /> */}
    </>
  )
}